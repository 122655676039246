import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/Observable";


@Injectable()
export class LoginGuard implements CanActivate {
    
    constructor(private _router: Router) {

    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean  {

        localStorage.removeItem('currentUser');
 
        return true;
    }
}
