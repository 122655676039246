import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs/Observable";


@Injectable()
export class SystemConfigurationGuard implements CanActivate {
    
    constructor(private _router: Router) {

    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean  {

        // if(localStorage.getItem('IsConfigExist') && localStorage.getItem('IsConfigExist')=="false" ){
            
        //     localStorage.setItem('currentUrl', state.url);
        // }else{
        //     this._router.navigate(['/login']);
        // };
 
        return true;
    }
}
