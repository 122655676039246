import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginGuard } from './luancher/login/login.guard';
import { PagesGuard } from './pages/pages.guard';
import { SystemConfigurationGuard } from './luancher/setting/system.configuration.guard';
export const routes: Routes = [
  {
    path: 'pages',
    canActivate:[PagesGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'login',
    canActivate:[LoginGuard],
    loadChildren: () => import('./luancher/login/login.module')
      .then(m => m.LoginModule),
  },
  {
    path: 'config',
    canActivate:[SystemConfigurationGuard],
    loadChildren: () => import('./luancher/setting/system.configuration.module')
      .then(m => m.SystemConfigModule),
  },
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
