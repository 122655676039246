import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { Http } from './shared/http-client/http-client.component';
@Injectable()
export class PagesGuard implements CanActivate, CanActivateChild {

    private ANONYMOUS: string = 'anonymous';

    constructor(private _router: Router, private _route: ActivatedRoute,private _http: Http) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if(this._http.checkAuthorized()){

            let authorized = childRoute.data.authorized;
   
            var user = this._http.getCurrentUser();
  
            switch(user.role.toLowerCase()){
                case 'user':
                        if(!(authorized == 'admin'))
                            return true;
                        else
                            this._router.navigate(['/login']);
                    break;
                case 'admin':
                default: 
                        return true;
                    break;
            }

            return true;
        }else{

            this._router.navigate(['/login']);
        }

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if(this._http.checkAuthorized()){

            
            return true;
        }else{

            this._router.navigate(['/login']);
        }
    }
}
