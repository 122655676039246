import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, UserData } from '../data/users';

@Injectable()
export class UserService extends UserData {

  private time: Date = new Date;

  private users = {
    nick: { name: 'Dispatch Admin',fullname:"Administrator", picture: 'assets/images/wolf.png' ,status:"online"},
    nick1: { name: 'CMD100001',fullname:"Camera 1", picture: 'assets/images/wolf.png' ,status:"online"},
    nick2: { name: 'CMD100002',fullname:"Camera 2", picture: 'assets/images/wolf.png' ,status:"online"},
    nick3: { name: 'CMD100003',fullname:"Camera 3", picture: 'assets/images/wolf.png' ,status:"online"},
    nick4: { name: 'CMD100004',fullname:"Camera 4", picture: 'assets/images/wolf.png' ,status:"online"},
    nick5: { name: 'CMD100005',fullname:"Camera 5", picture: 'assets/images/wolf.png' ,status:"offline"},
    nick6: { name: 'CMD100006',fullname:"Camera 6", picture: 'assets/images/wolf.png' ,status:"offline"},
    nick7: { name: 'CMD100007',fullname:"Camera 7", picture: 'assets/images/wolf.png' ,status:"offline"},
    nick8: { name: 'CMD100008',fullname:"Camera 8", picture: 'assets/images/wolf.png' ,status:"offline"},
  };
  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private contacts: Contacts[] = [
    { user: this.users.nick, type: this.types.mobile ,status:"online"},
    { user: this.users.nick1, type: this.types.mobile ,status:"online" },
    { user: this.users.nick2, type: this.types.mobile  ,status:"online"},
    { user: this.users.nick3, type: this.types.mobile  ,status:"online"},
    { user: this.users.nick4, type: this.types.mobile  ,status:"offline"},
    { user: this.users.nick5, type: this.types.mobile ,status:"offline" },
    { user: this.users.nick6, type: this.types.mobile  ,status:"offline"}
  ];
  private recentUsers: RecentUsers[]  = [
    { user: this.users.nick, type: this.types.mobile, time: this.time.setHours(5, 29) ,status:"online"},
    { user: this.users.nick2, type: this.types.mobile, time: this.time.setHours(5, 29) ,status:"online"},
    { user: this.users.nick3, type: this.types.mobile, time: this.time.setHours(5, 29),status:"offline"},
    { user: this.users.nick4, type: this.types.mobile, time: this.time.setHours(5, 29),status:"offline"}
  ];

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }
}
